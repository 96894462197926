<template>
  <div class="detail-row-class">
    <div class="row">
      <div class="col-6 my-auto text-center">
        <span v-html="rowData.html_embed"></span>
      </div>
      <div class="col-6 my-auto text-center">
        <p class="medium">HTML Embed:</p>
        <pre class="html-embed"> {{ rowData.html_embed }} </pre>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name  : 'LiveBroadcastDetail',
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
    },
    data() {
      return {
        created : moment(this.rowData.created_time)
          .format('MMM Do YYYY, h:mm:ss a'),
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import
    "../../../assets/scss/components/admin/broadcast/live-broadcast-detail";
</style>